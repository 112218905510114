import { Button } from "@/components/ui/button"
import * as Dialog from "@radix-ui/react-dialog"
import { XCircle, Lock, Mail } from "lucide-react"
import { CheckboxFormField } from "@/components/ui/checkbox/checkbox-form-field"
import { Form } from "@/components/ui/form"
import { InputFormField } from "@/components/ui/input/input-form-field"
import { Database } from "@/lib/database.types"
import { zodResolver } from "@hookform/resolvers/zod"
import { useSupabaseClient } from "@supabase/auth-helpers-react"
import { useForm } from "react-hook-form"
import { Link, useSearchParams } from "react-router-dom"
import { toast } from "sonner"
import { z } from "zod"
import EnquireSection from "../components/EnquireSection"
import { Svgs } from "../components/svgs"
import { useDialogHandler } from "@/src/hooks/use-dialog-handler"
import PageLayout from "../layouts/PageLayout"
import StarsIcon from "/confirm-email.svg"
import Template from "@/src/components/Template.tsx"
import { useState } from "react"

const registerFormSchema = z.object({
  email: z.string().email(),
  password: z.string().min(8, {
    message: "Password must be at least 8 characters",
  }),
  remember_me: z.boolean(),
})

const RegisterPage = () => {
  const { isDialogOpen, onOpenChange, onOpen } = useDialogHandler()

  const [searchParams] = useSearchParams()
  const supabase = useSupabaseClient<Database>()
  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword((val) => !val)
  }

  const registerForm = useForm<z.infer<typeof registerFormSchema>>({
    resolver: zodResolver(registerFormSchema),
    defaultValues: {
      email: searchParams.get("email") || "",
      password: "",
      remember_me: false,
    },
  })

  async function loginWithGoogle() {
    try {
      const { error } = await supabase.auth.signInWithOAuth({
        provider: "google",
        options: {
          redirectTo: window.location.origin + "/login",
        },
      })

      if (error) {
        toast.error(error.message)
        return
      }

      toast.success("Logged in successfully!")
    } catch (error) {
      console.error(error)
      toast.error(`Something went wrong: ${JSON.stringify(error)}`)
    }
  }

  async function handleRegister(data: z.infer<typeof registerFormSchema>) {
    try {
      const { error } = await supabase.auth.signUp({
        email: data.email,
        password: data.password,
        options: {},
      })

      if (error) {
        toast.error(error.message)
        return
      }

      toast.success("Account created successfully! Check your email for a verification Link.")
      onOpen()
    } catch (error) {
      console.error(error)
      toast.error(`Something went wrong: ${JSON.stringify(error)}`)
    }
  }

  return (
    <>
      <PageLayout className="grid lg:grid-cols-2 place-items-start">
        <div className="p-4 sticky top-0 w-full h-full flex flex-col justify-center items-center">
          <img
            className="-z-10 absolute w-full h-full object-cover"
            src="/register-page.jpeg"
            alt="Nauvoo"
            draggable={false}
          />

          <div className="relative bg-background p-4 sm:p-8 max-w-lg w-full m-auto rounded-sm space-y-4">
            <div className="w-full flex items-center gap-4 sm:gap-8">
              <h2>Register</h2>

              <Svgs.title_leaf className="w-full h-full mt-1" />
            </div>

            <div className="w-full relative">
              <Svgs.square_box className="w-full h-full" />
            </div>

            <Button size="xl" variant="white" className="w-full" onClick={loginWithGoogle}>
              <Svgs.google />
              Sign up with Google
            </Button>

            <div className="w-full flex items-center gap-1">
              <hr className="w-full" />
              <h6 className="font-bold not-italic font-inter">OR</h6>
              <hr className="w-full" />
            </div>

            <Form {...registerForm}>
              <form onSubmit={registerForm.handleSubmit(handleRegister)} className="grid gap-4">
                <InputFormField
                  Icon={Mail}
                  label="Email Address"
                  type="email"
                  name="email"
                  placeholder="johndoe@gmail.com"
                  description="Your email is case sensitive."
                  required
                />

                <InputFormField
                  Icon={Lock}
                  label="Create Password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="*****************"
                  required
                />
                <CheckboxFormField name="" label="Show password" onClick={togglePasswordVisibility} />

                <CheckboxFormField label="Remember Me?" name="remember_me" />

                <Button size="lg">Sign Up</Button>

                <div className="flex flex-col md:flex-row items-center justify-between gap-4">
                  <p className="text-sm text-primary-500">
                    Already have an account?{" "}
                    <Link className="text-sm underline" to={`/login?email=${registerForm.watch("email") || ""}`}>
                      Login
                    </Link>
                  </p>

                  <Link className="text-primary-500 text-sm hover:underline" to="/forgot-password">
                    Forgot Password?
                  </Link>
                </div>
              </form>
            </Form>
          </div>
        </div>

        <div className="h-full hidden lg:flex p-10 flex-col justify-between">
          <div className="space-y-8">
            <Template slug="login/#blurb" />
          </div>

          <EnquireSection isSmallScreen />
        </div>
      </PageLayout>
      <Dialog.Root open={isDialogOpen} onOpenChange={onOpenChange}>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 animate-fadeInUp" />
          <Dialog.Content className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 flex justify-center animate-fadeInUp">
            <div className="relative border-2 border-[#4F361B] flex flex-col items-center bg-white gap-6 px-12 py-8 rounded-xl text-center">
              <img src={StarsIcon} alt="" />
              <p className="text-[#6B7280] text-2xl font-semibold">Confirm Email</p>
              <p className="text-[#6B7280] text-xl font-light w-60">Confirm your email to log in.</p>
              <Dialog.Close asChild className="absolute right-4 top-4 cursor-pointer">
                <XCircle size={28} />
              </Dialog.Close>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  )
}

export default RegisterPage
