"use client"

import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  TableOptions,
  Table as TableType,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { ChevronDown, Loader2, RefreshCcw } from "lucide-react"
import * as React from "react"

import { Button } from "@/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Select } from "@/components/ui/select/index"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { cn } from "@/lib/utils"

import "@tanstack/react-table"

declare module "@tanstack/react-table" {
  interface Row<TData> {
    className?: string
  }
}

type Props<T = any, D = any> = {
  columns: ColumnDef<T>[]
  data: D[]
  setPageCount?: (table: TableType<any>) => void
  TopActionButtons?: (table: TableType<any>) => React.ReactNode
  EndActionButtons?: (table: TableType<any>) => React.ReactNode
  isLoading?: boolean
  hideOtherActions?: boolean
  hidePagination?: boolean
}

export const DataTable = ({
  columns,
  data,
  setPageCount = (table) => {
    table.setPageSize(50)
  },
  TopActionButtons,
  EndActionButtons,
  isLoading,
  hideOtherActions = false,
  hidePagination = false,
}: Props) => {
  const [isReloading, setIsReloading] = React.useState(false)
  const [sorting, setSorting] = React.useState<SortingState>([])
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([])
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({})
  const [rowSelection, setRowSelection] = React.useState({})

  let tableOptions: TableOptions<any> = {
    data: data || [],
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  }

  if (!hidePagination) {
    tableOptions = {
      ...tableOptions,
      getPaginationRowModel: getPaginationRowModel(),
    }
  }

  const table = useReactTable(tableOptions)

  const columnsForDropdown = table.getAllColumns().filter((column) => column.getCanHide())

  const columnVisibilityInit: VisibilityState = (() => {
    const vs: VisibilityState = {}
    columnsForDropdown.map((col) => (vs[col.id] = col.getIsVisible()))
    return vs
  })()

  const [tempColumnVisibility, setTempColumnVisibility] = React.useState<VisibilityState>(columnVisibilityInit)

  React.useEffect(() => {
    setPageCount?.(table)
  }, [])

  return (
    <div className="border-2 w-full grid p-4 gap-4">
      <div className="w-full flex flex-col md:flex-row items-center justify-between gap-2 md:gap-4">
        {TopActionButtons?.(table)}

        <div className="flex flex-wrap items-center justify-center gap-2">
          {EndActionButtons?.(table)}

          {!hideOtherActions && (
            <>
              <Button
                onClick={() => {
                  setIsReloading(true)
                  setTimeout(() => {
                    // refresh();
                    setIsReloading(false)
                  }, 1000)
                }}
                variant="outline"
              >
                <RefreshCcw className={cn(isReloading && "animate-spin")} size={16} />
              </Button>

              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline" className="ml-auto text-sm">
                    Columns <ChevronDown className="ml-2 h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>

                <DropdownMenuContent align="end">
                  {columnsForDropdown.map(
                    (column) =>
                      column.columnDef.header && (
                        <DropdownMenuCheckboxItem
                          onSelect={(e) => e.preventDefault()}
                          key={column.id}
                          className="capitalize cursor-pointer"
                          checked={tempColumnVisibility[column.id]}
                          onCheckedChange={(value) =>
                            setTempColumnVisibility((v) => {
                              return {
                                ...v,
                                [column.id]: value,
                              }
                            })
                          }
                        >
                          {typeof column.columnDef.header === "string" ? column.columnDef.header : column.id}
                        </DropdownMenuCheckboxItem>
                      ),
                  )}
                  <div className="text-right">
                    <Button
                      className="mt-2"
                      onClick={() => {
                        setColumnVisibility(tempColumnVisibility)
                      }}
                    >
                      Apply
                    </Button>
                  </div>
                </DropdownMenuContent>
              </DropdownMenu>
            </>
          )}
        </div>
      </div>

      {isLoading ? (
        <div className="grid place-content-center">
          <Loader2 className="animate-spin w-6 h-6" />
        </div>
      ) : (
        <div className="overflow-x-scroll max-w-full">
          <Table>
            <TableHeader className="z-40 sticky top-0">
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id} className="!bg-primary-100 !rounded-none">
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id} className="whitespace-nowrap">
                        {header.isPlaceholder
                          ? undefined
                          : flexRender(header.column.columnDef.header, header.getContext())}
                      </TableHead>
                    )
                  })}
                </TableRow>
              ))}
            </TableHeader>

            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow key={row.id} className={row?.className} data-state={row.getIsSelected() && "selected"}>
                    {row.getVisibleCells().map((cell) => (
                      <TableCell
                        key={cell.id}
                        style={{
                          width: cell.column.columnDef.size,
                        }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} className="h-24 text-center">
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      )}

      {!hidePagination && (
        <div className="flex flex-col-reverse sm:flex-row items-center justify-center sm:justify-between gap-2">
          <div className="flex items-center gap-4">
            <div className="flex-1 text-sm text-muted-foreground">
              {table.getFilteredSelectedRowModel().rows.length} of {table.getFilteredRowModel().rows.length} row(s)
              selected.
            </div>

            <Select
              className="w-28 h-8 py-1"
              value={table.getState().pagination.pageSize + ""}
              onValueChange={(value) => {
                table.setPageSize(Number(value))
              }}
              options={[50, 100, 500, 1000].map((label) => ({
                label: `Show ${label}`,
                value: `${label}`,
              }))}
            />
          </div>

          <div className="space-x-2">
            <Button
              variant="outline"
              size="sm"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              Previous
            </Button>
            <Button variant="outline" size="sm" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
              Next
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
