import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input/index"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Database } from "@/lib/database.types"
import { cn } from "@/lib/utils"
import { DataTable } from "@/src/components/DataTable"
import { useSession, useSupabaseClient } from "@supabase/auth-helpers-react"
import { ColumnDef } from "@tanstack/react-table"
import { Loader2, Pencil, SearchIcon } from "lucide-react"
import { useEffect, useMemo, useState } from "react"
// import { useForm } from "react-hook-form"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
// import { useDialogHandler } from "../../hooks/use-dialog-handler"
import PageLayout from "../../layouts/PageLayout"
import { QueryData } from "@supabase/supabase-js"
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card.tsx"

import {
  ageGroups,
  getAgeInYears,
  getAgeGroupsForApplication,
  otherGroup as otherGroupKey,
} from "@/src/utils/castAgeGroups"
import { toast } from "sonner"

// duplicated from Summary.tsx
const QUERY_STRING = `id, notification_emails, state, description, previous_participation, previous_application, notification_cellphones, address1, address2, city, state, zip, country, name, 
  application_assignment (application_id, group_cast (id, slug, group_type, description), district (id, name)), application_comment (id, comment, user_email, modified_at), applicant ( id, given_name, family_name, female, birth_date ), application_availability (preference_order, group_cast ( group_type, description, slug )), is_checked ( id, application_id, application_checklist_item ( id, content ) )`
const NOT_EXECUTED = () => useSupabaseClient<Database>().from("application").select(QUERY_STRING).single()
type FullApplication = QueryData<ReturnType<typeof NOT_EXECUTED>>

const castingGroupAttr_type = "group_type"
const castingGroupAttr_slug = "slug"

type assignableCastingGroup = {
  key: string
  value: string
}

type checklistItem = {
  itemId: string
  checklistId: string
  group_type: string
  title: string
}

type isCheckedItem = {
  itemId: string
  isCheckedId: string
  title: string
}

type district = {
  id: string
  name: string
}

const FamilyCast = () => {
  const supabase = useSupabaseClient<Database>()

  const navigation = useNavigate()
  const [searchParams] = useSearchParams()
  const session = useSession()

  // const { isDialogOpen, onOpenChange, onOpen } = useDialogHandler()
  //
  // const form = useForm()

  const castingGroup = searchParams.get("casting_group")
  const castingGroupAttr = searchParams.get("casting_group_attr") || castingGroupAttr_slug

  const [copiedEmail, setCopiedEmail] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const [selectedCastingGroup, setSelectedCastingGroup] = useState(castingGroup || "")
  const [selectedCastingGroupAttr, setSelectedCastingGroupAttr] = useState(castingGroupAttr || "")
  const [applications, setApplications] = useState<FullApplication[]>([])
  const [availableDistricts, setAvailableDistricts] = useState<district[]>([])
  const [unassignedDistrictId, setUnassignedDistrictId] = useState("")
  const [assignableCastingGroups, setAssignableGroupCasts] = useState<assignableCastingGroup[]>([])
  const [checklistItems, setChecklistItems] = useState<checklistItem[]>([])

  useEffect(() => {
    const fetchCastChecklistItems = async () => {
      const { data, error } = await supabase
        .from("application_checklist")
        .select("id, group_type, application_checklist_item ( id, content )")

      if (error) {
        console.error("error loading application_checklist", error)
        return
      }

      if (data) {
        setChecklistItems(
          data.flatMap((v) => {
            return v.application_checklist_item
              .map((item) => {
                return {
                  itemId: item.id,
                  title: item.content,
                  group_type: v.group_type,
                  checklistId: v.id,
                } as checklistItem
              })
              .sort()
          }),
        )
      }
    }

    const fetchAssignableGroupCasts = async () => {
      const { data, error } = await supabase
        .from("group_cast")
        .select("slug, description")
        .neq("group_type", "CORE_CAST")
        .neq("group_type", "DIRECTING_STAFF")
        .neq("group_type", "VOLUNTEER")
        .neq("slug", "fair_red")
        .neq("slug", "fair_blue")
        .neq("slug", "fair_yellow")
        .neq("slug", "fair_green")
        .neq("slug", "fair_gold")

      if (error) {
        console.error("error loading group_cast list", error)
        return
      }

      if (data) {
        setAssignableGroupCasts(
          data.map((v) => {
            return { key: v.slug, value: v.description || "" }
          }),
        )
      }
    }

    async function fetchUnassignedDistrict() {
      const { error, data } = await supabase.from("district").select("id, name")
      if (error) {
        console.error("Error fetching default district", error)
      }
      if (!data) {
        return
      }
      setAvailableDistricts(data)
      setUnassignedDistrictId(data!.find((v) => v.name === "UNASSIGNED")!.id)
    }

    async function fetchApplicants() {
      const { error, data } = await supabase
        .from("application")
        .select(QUERY_STRING)
        .not("first_submitted_at", "is", null)
      if (error) {
        console.error("Error fetching applications", error)
      }
      if (!data) {
        return
      }

      setApplications([...data])

      await fetchUnassignedDistrict()
      await fetchAssignableGroupCasts()
      await fetchCastChecklistItems()
      setIsLoading(false)
    }

    fetchApplicants()
  }, [supabase])

  const parsedApplications = useMemo(() => {
    return applications.map(
      ({
        id,
        name,
        notification_emails,
        notification_cellphones,
        address1,
        address2,
        city,
        state,
        zip,
        country,
        previous_application,
        previous_participation,
        description,
        application_availability,
        applicant,
        application_comment,
        application_assignment,
        is_checked,
      }) => ({
        id,
        description,
        name,
        notification_emails,
        notification_cellphones,
        state,
        address: [address1, address2, `${city}, ${state} ${zip}`, country],
        application_availability: application_availability
          .filter((a) => a.group_cast != null)
          .sort((a, b) => a.preference_order - b.preference_order),
        applicant,
        participation_details: `Participation: ${previous_participation?.join(",") || "None"} Rejection: ${previous_application?.filter((year) => previous_participation?.indexOf(year) === -1).join(",") || "None"}`,
        admissions: `${previous_participation?.length || 0} / ${(previous_application?.length || 0) + (previous_participation?.length || 0)}`,
        icon_url: supabase.storage.from("family_photo").getPublicUrl(`${id}.jpg`, {
          transform: {
            height: 256,
            resize: "contain",
          },
        }).data.publicUrl,
        full_url: supabase.storage.from("family_photo").getPublicUrl(`${id}.jpg`, {
          transform: {
            width: 1920,
            resize: "contain",
          },
        }).data.publicUrl,
        application_comment,
        application_assignment,
        ...getAgeGroupsForApplication(applicant),
        checklist_items: is_checked.map((v) => {
          return {
            isCheckedId: v.id,
            itemId: v.application_checklist_item?.id,
            title: v.application_checklist_item?.content,
          } as isCheckedItem
        }),
      }),
    )
  }, [applications, supabase.storage])

  const getFilteredApplicationsBySlug = (input: typeof parsedApplications) => {
    return input.filter((app) => {
      if (app.application_assignment.length > 0 && app.application_assignment[0].group_cast?.slug) {
        return app.application_assignment[0].group_cast?.slug == selectedCastingGroup
      }
      if (app.application_availability?.length < 1) {
        return false
      }
      if (app.application_availability[0].group_cast == null) {
        return false
      }
      return app.application_availability[0].group_cast.slug == selectedCastingGroup
    })
  }

  const getFilteredApplicationsByGroupType = (input: typeof parsedApplications) => {
    return input.filter((app) => {
      if (app.application_assignment.length > 0 && app.application_assignment[0].group_cast?.group_type) {
        return app.application_assignment[0].group_cast?.group_type == selectedCastingGroup
      }
      if (app.application_availability?.length < 1) {
        return false
      }
      if (app.application_availability[0].group_cast == null) {
        return false
      }
      return app.application_availability[0].group_cast.group_type == selectedCastingGroup
    })
  }
  let filteredApplications = parsedApplications
  if (selectedCastingGroup != "") {
    if (selectedCastingGroupAttr === castingGroupAttr_type) {
      filteredApplications = getFilteredApplicationsByGroupType(parsedApplications)
    } else {
      filteredApplications = getFilteredApplicationsBySlug(parsedApplications)
    }
  }

  const ageGroupTotals: Record<string, number> = Object.fromEntries(
    [otherGroupKey, ...ageGroups.map((ag) => ag.key())].map((agKey) => {
      const total = filteredApplications.reduce((prev, curr) => {
        if (!curr.application_assignment[0]) {
          return prev
        }
        if (
          selectedCastingGroupAttr === castingGroupAttr_type &&
          curr.application_assignment[0].group_cast?.group_type == selectedCastingGroup
        ) {
          return prev
        }
        if (curr.application_assignment[0].group_cast?.slug != selectedCastingGroup) {
          return prev
        }
        return prev + (curr as unknown as Record<string, number>)[agKey]
      }, 0)
      return [agKey, total]
    }),
  )

  const totalAssignedApplicants = Object.entries(ageGroupTotals)
    .map((v) => v[1])
    .reduce((prev, curr) => prev + curr, 0)

  const totalApplicantsColumn: ColumnDef<(typeof filteredApplications)[0]> = {
    accessorKey: "Total",
    header: () => (
      <div className="grid family-cast-table-header">
        <span>Total Assigned ({totalAssignedApplicants})</span>
      </div>
    ),
    cell: ({ row }) => {
      return <span>{(row.getValue("applicant") as []).length}</span>
    },
  }

  const ageGroupColumns: ColumnDef<(typeof filteredApplications)[0]>[] = ageGroups.map((ag) => {
    return {
      accessorKey: ag.key(),
      header: () => (
        <div className="grid family-cast-table-header">
          <span className={ag.className(ageGroupTotals[ag.key()])}>{ag.display(ageGroupTotals[ag.key()])}</span>
        </div>
      ),
      cell: ({ row }) => {
        return <span>{row.getValue(ag.key())}</span>
      },
    }
  })

  const otherColumn: ColumnDef<(typeof filteredApplications)[0]> = {
    accessorKey: otherGroupKey,
    header: () => (
      <div className="grid family-cast-table-header">
        <span>Other ({ageGroupTotals[otherGroupKey]})</span>
      </div>
    ),
    cell: ({ row }) => {
      return <span>{row.getValue(otherGroupKey)}</span>
    },
  }

  const checklistColumns: ColumnDef<(typeof filteredApplications)[0]>[] = checklistItems.map((item) => {
    return {
      accessorKey: item.title,
      header: () => (
        <div className="grid family-cast-table-header">
          <span>{item.title}</span>
        </div>
      ),
      cell: ({ row }) => {
        const rowItems = row.original.checklist_items
        const applicationId = row.original.id
        const isCheckedRow = rowItems.find((v) => v.itemId == item.itemId)
        const checked = !!isCheckedRow
        return (
          <input
            type="checkbox"
            checked={checked}
            onChange={async (e) => {
              if (e.target.checked) {
                // make checked
                const { data, error } = await supabase
                  .from("is_checked")
                  .insert({
                    application_id: applicationId,
                    item: item.itemId,
                    checked_by: session?.user.id,
                  })
                  .select("application_id, id, checked_by, checked_at, application_checklist_item ( id, content )")
                  .single()

                if (error) {
                  toast.error(`Failed to update: ${JSON.stringify(error)}`)
                  console.error(error)
                  return
                }

                const thisApplication = applications.find((app) => app.id === applicationId)!
                const index = applications.indexOf(thisApplication)
                thisApplication.is_checked.push(data!)
                const newApplications = [
                  ...applications.slice(0, index),
                  thisApplication,
                  ...applications.slice(index + 1, applications.length),
                ]
                setApplications(newApplications)
              } else {
                // uncheck
                const { error } = await supabase
                  .from("is_checked")
                  .delete()
                  .eq("application_id", applicationId)
                  .eq("id", isCheckedRow!.isCheckedId)

                if (error) {
                  toast.error(JSON.stringify(error))
                  return
                }

                const thisApplication = applications.find((app) => app.id === applicationId)!
                const index = applications.indexOf(thisApplication)
                thisApplication.is_checked = [
                  ...thisApplication.is_checked.filter((v) => v.id != isCheckedRow?.isCheckedId),
                ]
                const newApplications = [
                  ...applications.slice(0, index),
                  thisApplication,
                  ...applications.slice(index + 1, applications.length),
                ]
                setApplications(newApplications)
              }
            }}
          />
        )
      },
    }
  })

  const columns: ColumnDef<(typeof filteredApplications)[0]>[] = [
    // {
    //   id: "select",
    //   header: ({ table }) => (
    //     <Checkbox
    //       checked={table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && "indeterminate")}
    //       onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
    //       aria-label="Select all"
    //     />
    //   ),
    //   cell: ({ row }) => (
    //     <Checkbox
    //       checked={row.getIsSelected()}
    //       onCheckedChange={(value) => row.toggleSelected(!!value)}
    //       aria-label="Select row"
    //     />
    //   ),
    // },
    // {
    //   accessorKey: "link",
    //   header: "Link to Application",
    //   cell: ({ row }) => (
    //     <Link className="w-20 center" to={`/apply/${row.original.id ?? "_"}/confirm`}>
    //       <Button variant={"link"}>{`${row.original.id ?? "_"}`}</Button>
    //     </Link>
    //   ),
    // },
    {
      accessorKey: "name",
      header: () => (
        <div className="grid family-cast-table-header">
          <span>Application Name</span>
        </div>
      ),
      cell: ({ row }) => (
        <Link className="flex flex-row items-stretch h-full" to={`/apply/${row.original.id ?? "_"}/summary`}>
          <Button variant={"link"}>{row.getValue("name")}</Button>
        </Link>
      ),
    },
    {
      accessorKey: "application_availability",
      header: () => (
        <div className="grid family-cast-table-header">
          <span>Casts Available</span>
        </div>
      ),
      cell: ({ row }) => (
        <ol>
          {row.getValue<FullApplication["application_availability"]>("application_availability").map((a) => (
            <li key={a.group_cast!.description} className="whitespace-nowrap">
              {a.preference_order}. {a.group_cast!.description}
            </li>
          ))}
        </ol>
      ),
    },
    {
      id: "district",
      accessorKey: "",
      header: () => (
        <div className="grid family-cast-table-header">
          <span>District</span>
        </div>
      ),
      cell: ({ row }) => {
        const applicationId = row.original.id
        const assignment = row.getValue<FullApplication["application_assignment"]>("application_assignment")
        const currentGroupCastId = (() => {
          try {
            return assignment[0]!.group_cast!.id
          } catch {
            return ""
          }
        })()
        const currentDistrict = (() => {
          try {
            return assignment[0].district!
          } catch {
            return { id: unassignedDistrictId, name: "UNASSIGNED" }
          }
        })()
        return (
          <Select
            value={currentDistrict.id}
            onValueChange={async (val) => {
              setApplications((currentApplications) => {
                return currentApplications.map((application) => {
                  if (application.id === applicationId) {
                    return {
                      ...application,
                      application_assignment: [
                        {
                          ...application.application_assignment[0],
                          district: {
                            id: val,
                            name: availableDistricts!.find((v) => v.id === val)!.name,
                          },
                        },
                      ],
                    }
                  }
                  return application
                })
              })

              const delResponse = await supabase
                .from("application_assignment")
                .delete()
                .eq("application_id", applicationId)
              if (delResponse.error) {
                toast.error(`Error assigning district: ${JSON.stringify(delResponse.error)}`)
                return
              } else {
                toast.success("Assigned to district successfully")
              }

              const { error } = await supabase.from("application_assignment").insert({
                application_id: applicationId,
                group_cast_id: currentGroupCastId,
                district_id: val,
              })

              if (error) {
                toast.error(`Error assigning district: ${JSON.stringify(error)}`)
              } else {
                toast.success("Assigned to district successfully")
              }
            }}
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="" />
            </SelectTrigger>

            <SelectContent>
              {availableDistricts.map(({ id, name }) => (
                <SelectItem key={name} value={id}>
                  {name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        )
      },
    },
    {
      accessorKey: "application_assignment",
      header: () => (
        <div className="grid family-cast-table-header">
          <span>Assigned Cast</span>
        </div>
      ),
      cell: ({ row }) => {
        const applicationId = row.original.id
        const assignment = row.getValue<FullApplication["application_assignment"]>("application_assignment")
        const currentCastAssignmentValue = (() => {
          if (assignment.length < 0) {
            return ""
          }
          if (!assignment[0]) {
            return ""
          }
          if (!assignment[0].group_cast) {
            return ""
          }
          if (!assignment[0].group_cast?.slug) {
            return ""
          }
          return assignment[0].group_cast.slug
        })()
        return (
          <Select
            value={currentCastAssignmentValue}
            onValueChange={async (val) => {
              setIsLoading(true)

              await supabase.from("application_assignment").delete().eq("application_id", applicationId)

              type myResponse = {
                id: string
                slug: string
                description: string | null
                group_type: Database["public"]["Tables"]["group_cast"]["Row"]["group_type"]
              }

              let response: myResponse

              if (val) {
                const result = await supabase.from("group_cast").select("id").eq("slug", val).single()

                const { data } = await supabase
                  .from("application_assignment")
                  .insert({
                    application_id: applicationId,
                    group_cast_id: result.data!.id,
                    district_id: unassignedDistrictId,
                  })
                  .select("group_cast_id (id, slug, group_type, description)")
                  .single()

                response = data!.group_cast_id as unknown as myResponse
              } else {
                response = {
                  slug: val,
                } as myResponse
              }

              const thisApplication = applications.find((app) => app.id === applicationId)!
              const index = applications.indexOf(thisApplication)
              thisApplication.application_assignment = [
                {
                  application_id: applicationId,
                  group_cast: response!,
                  district: {
                    id: unassignedDistrictId,
                    name: "UNASSIGNED",
                  },
                },
              ]

              const newApplications = [
                ...applications.slice(0, index),
                thisApplication,
                ...applications.slice(index + 1, applications.length),
              ]
              setApplications(newApplications)
              setIsLoading(false)
            }}
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="" />
            </SelectTrigger>

            <SelectContent>
              <SelectItem value="">None</SelectItem>

              {assignableCastingGroups.map(({ key, value }) => (
                <SelectItem key={key} value={key}>
                  {value}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        )
      },
    },

    // {
    //   accessorKey: "cast_pref",
    //   header: () => (
    //     <div className="grid family-cast-table-header">
    //       <span className="text-transparent select-none">X</span>
    //       <span>{filteredApplications.length}</span>
    //       <span>Cast Preference</span>

    //       <div className="!p-0 !px-2 flex items-center border-b">
    //         <p className="bg-rose-100 w-8 grid place-content-center py-2 text-sm">R</p>
    //         <p className="bg-blue-100 w-8 grid place-content-center py-2 text-sm">B</p>
    //         <p className="bg-yellow-100 w-8 grid place-content-center py-2 text-sm">Y</p>
    //         <p className="bg-green-100 w-8 grid place-content-center py-2 text-sm">Gr</p>
    //         <p className="bg-orange-100 w-8 grid place-content-center py-2 text-sm">Go</p>
    //       </div>
    //     </div>
    //   ),
    //   cell: () => {
    //     return (
    //       <div className="!p-0 flex items-center">
    //         <p className="bg-rose-100 w-8 grid place-content-center py-2 text-sm">
    //           <Check className="w-5 h-5" />
    //         </p>
    //         <p className="bg-blue-100 w-8 grid place-content-center py-2 text-sm">
    //           <Check className="w-5 h-5" />
    //         </p>
    //         <p className="bg-yellow-100 w-8 grid place-content-center py-2 text-sm">
    //           <Check className="w-5 h-5" />
    //         </p>
    //         <p className="bg-green-100 w-8 grid place-content-center py-2 text-sm">
    //           <Check className="w-5 h-5" />
    //         </p>
    //         <p className="bg-orange-100 w-8 grid place-content-center py-2 text-sm">
    //           <Check className="w-5 h-5" />
    //         </p>
    //       </div>
    //     )
    //   },
    // },
    {
      accessorKey: "photo",
      header: () => (
        <div className="grid family-cast-table-header">
          <span>Photo</span>
        </div>
      ),
      cell: ({ row }) => (
        <HoverCard>
          <HoverCardTrigger>
            <img src={row.original.icon_url} width={256} height={256} />
          </HoverCardTrigger>
          {/*todo: fix hovercard not finding default background color*/}
          <HoverCardContent className="bg-white h-fit w-fit">
            <img className="max-w-[100vw] max-h-[50vh]" src={row.original.full_url} />
          </HoverCardContent>
        </HoverCard>
      ),
    },
    {
      accessorKey: "description",
      header: () => (
        <div className="grid family-cast-table-header">
          <span>Bio</span>
        </div>
      ),
      cell: ({ row }) => (
        <div className="w-80">{row.getValue("description")}</div>
        // <HoverCard>
        //   <HoverCardTrigger>
        //     <div className="w-80">
        //       {row.getValue("description")}
        //     </div>
        //   </HoverCardTrigger>
        //   <HoverCardContent className="bg-white w-80">{row.getValue("description")}</HoverCardContent>
        // </HoverCard>
      ),
    },
    {
      accessorKey: "admissions",
      header: () => (
        <div className="grid family-cast-table-header">
          <span>Participated/Applied</span>
        </div>
      ),
    },
    {
      accessorKey: "participation_details",
      header: () => (
        <div className="grid family-cast-table-header">
          <span>Participation Details</span>
        </div>
      ),
    },
    {
      accessorKey: "application_comment",
      header: () => (
        <div className="grid family-cast-table-header">
          <span>Notes</span>
        </div>
      ),
      cell: ({ row }) => {
        return (
          <ol>
            {row.getValue<FullApplication["application_comment"]>("application_comment").map((comment) => (
              <div key={comment.id} className="w-80">
                {comment.comment}{" "}
                <i>
                  (last updated by {comment.user_email} on {new Date(comment.modified_at).toLocaleDateString()} at{" "}
                  {new Date(comment.modified_at).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })})
                </i>
              </div>
            ))}
          </ol>
        )
      },
    },
    {
      accessorKey: "applicant",
      header: () => (
        <div className="grid family-cast-table-header">
          <span>Family</span>
        </div>
      ),
      cell: ({ row }) => {
        return (
          <ol>
            {row.getValue<FullApplication["applicant"]>("applicant").map((a) => (
              <li key={a.id} className="whitespace-nowrap">
                {a.given_name} {a.family_name} {a.birth_date ? getAgeInYears(a.birth_date) : ""}
                {a.female ? "F" : "M"}
              </li>
            ))}
          </ol>
        )
      },
    },
    {
      accessorKey: "state",
      header: () => (
        <div className="grid family-cast-table-header">
          <span>State</span>
        </div>
      ),
    },
    {
      accessorKey: "address",
      header: () => (
        <div className="grid family-cast-table-header">
          <span>Phone numbers and Address</span>
        </div>
      ),
      cell: ({ row }) => (
        <div>
          {row.original?.notification_cellphones?.map((phoneNumber) => (
            <span key={phoneNumber}>
              {phoneNumber}
              <br />
            </span>
          ))}
          <hr />
          <ul>
            {row.getValue<string[]>("address").map((item: string, index: number) => (
              <li key={index} className="whitespace-nowrap">
                {item}
              </li>
            ))}
          </ul>
        </div>
      ),
    },
    {
      accessorKey: "notification_emails",
      header: () => (
        <div className="grid family-cast-table-header">
          <span>Emails</span>
        </div>
      ),
      cell: ({ row }) => {
        return (
          <div className="grid">
            {row.original?.notification_emails?.map((email) => (
              <span
                className={cn(
                  "px-2 py-1 w-fit relative text-blue-600 cursor-pointer underline",
                  copiedEmail === `${row.original}-${email}` && "text-black",
                )}
                onClick={async () => {
                  setCopiedEmail(`${row.original}-${email}`)

                  await navigator.clipboard.writeText(email)
                }}
                key={email}
              >
                {email}

                {copiedEmail === `${row.original}-${email}` && (
                  <span className="top-0 left-0 absolute w-full h-full bg-green-500/90 rounded-md font-semibold text-center grid place-content-center">
                    Copied
                  </span>
                )}
              </span>
            ))}
          </div>
        )
      },
    },
    totalApplicantsColumn,
    ...(selectedCastingGroup ? [...ageGroupColumns, otherColumn] : []),
    ...checklistColumns,
  ]

  //Costuming, Family Support, Medical, Tech Crew, Country Fair, Administrative Staff, Bagpipers, Food Services, Security, Declined, Spanish

  //Constuming, Family Support, Medical, Tech Crew, Country Fair, Bagpipes, Food Services, Security,
  const filterableCastingGroups = [
    {
      key: "family_red",
      type: "slug",
      value: "Red Cast",
    },
    {
      key: "family_blue",
      type: "slug",
      value: "Blue Cast",
    },
    {
      key: "family_yellow",
      type: "slug",
      value: "Yellow Cast",
    },
    {
      key: "family_green",
      type: "slug",
      value: "Green Cast",
    },
    {
      key: "family_gold",
      type: "slug",
      value: "Gold Cast",
    },
    {
      key: "administrative_staff",
      type: "slug",
      value: "Administrative Staff",
    },
    {
      key: "BAGPIPE_BAND",
      type: "group_type",
      value: "Bagpipe Band",
    },
    {
      key: "COSTUMING",
      type: "group_type",
      value: "Costuming",
    },
    {
      key: "COUNTRY_FAIR",
      type: "group_type",
      value: "Country Fair",
    },
    {
      key: "FAMILY_SUPPORT",
      type: "group_type",
      value: "Family Support",
    },
    {
      key: "FOOD_SERVICES",
      type: "group_type",
      value: "Food Services",
    },
    {
      key: "MEDICAL",
      type: "group_type",
      value: "Medical",
    },
    {
      key: "SECURITY",
      type: "group_type",
      value: "Security",
    },
    {
      key: "WORK_CREW",
      type: "group_type",
      value: "Tech Crew",
    },
    {
      key: "no_cast",
      type: "slug",
      value: "Not Cast",
    },
    {
      key: "withdrawn",
      type: "slug",
      value: "Withdrawn",
    },
  ]

  return (
    <PageLayout className="p-4 gap-8 family-cast-table" showFullLogo>
      {isLoading ? (
        <div className="flex-1 grid place-content-center">
          <Loader2 className="w-6 h-6 animate-spin" />
        </div>
      ) : (
        <DataTable
          isLoading={isLoading}
          TopActionButtons={() => (
            <div className="relative flex items-center">
              <SearchIcon className="ml-2 absolute w-5 h-5 text-[#85807C]" />
              <Input placeholder="Search" className="h-9 bg-white pl-10" />
            </div>
          )}
          EndActionButtons={() => (
            <Select
              value={selectedCastingGroup}
              onValueChange={(e) => {
                setSelectedCastingGroup(e)
                const cg = filterableCastingGroups.find((cg) => cg.key === e)
                if (cg) {
                  setSelectedCastingGroupAttr(cg.type)
                } else {
                  setSelectedCastingGroupAttr("")
                }
                if (e != "" && cg) {
                  navigation(`/casting/family?casting_group=${e}&casting_group_attr=${cg.type}`)
                } else if (e != "") {
                  navigation(`/casting/family?casting_group=${e}`)
                } else {
                  navigation(`/casting/family`)
                }
              }}
            >
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="" />
              </SelectTrigger>

              <SelectContent>
                <SelectItem value="">None</SelectItem>

                {filterableCastingGroups.map(({ key, value }) => (
                  <SelectItem key={key} value={key}>
                    {value}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}
          columns={columns}
          data={filteredApplications}
          hidePagination
        />
      )}
    </PageLayout>
  )
}

export default FamilyCast
