import { Button } from "@/components/ui/button"
import { CheckboxFormField } from "@/components/ui/checkbox/checkbox-form-field"
import { Form } from "@/components/ui/form"
import { InputFormField } from "@/components/ui/input/input-form-field"
import { Database } from "@/lib/database.types"
import { zodResolver } from "@hookform/resolvers/zod"
import { useSupabaseClient } from "@supabase/auth-helpers-react"
import { Lock, Mail } from "lucide-react"
import { useForm } from "react-hook-form"
import { Link, useSearchParams } from "react-router-dom"
import { toast } from "sonner"
import { z } from "zod"
import EnquireSection from "../components/EnquireSection"
import { Svgs } from "../components/svgs"
import PageLayout from "../layouts/PageLayout"
import { useDocumentTitle } from "@/src/hooks/UseDocumentTitle.ts"
import Template from "@/src/components/Template.tsx"
import { useState } from "react"

const loginFormSchema = z.object({
  email: z.string().email(),
  password: z.string().min(8, {
    message: "Password must be at least 8 characters",
  }),
  remember_me: z.boolean(),
})

const LoginPage = () => {
  useDocumentTitle(["Log in"])
  const [searchParams] = useSearchParams()
  const [showPassword, setShowPassword] = useState(false)

  const supabase = useSupabaseClient<Database>()

  const loginForm = useForm<z.infer<typeof loginFormSchema>>({
    resolver: zodResolver(loginFormSchema),
    defaultValues: {
      email: searchParams.get("email") || "",
      password: "",
      remember_me: false,
    },
  })

  const togglePasswordVisibility = () => {
    setShowPassword((val) => !val)
  }

  async function loginWithGoogle() {
    try {
      const { error } = await supabase.auth.signInWithOAuth({
        provider: "google",
        options: {
          redirectTo: window.location.origin + "/login",
        },
      })

      if (error) {
        toast.error(error.message)
        return
      }

      toast.success("Logged in successfully!")
    } catch (error) {
      console.error(error)
      toast.error(`Something went wrong: ${JSON.stringify(error)}`)
    }
  }

  async function handleLogin(data: z.infer<typeof loginFormSchema>) {
    try {
      const { error } = await supabase.auth.signInWithPassword({
        email: data.email,
        password: data.password,
      })
      if (error) {
        toast.error(error.message)
        return
      }
      toast.success("Logged in successfully!")
    } catch (error) {
      console.error(error)
      toast.error(`Something went wrong: ${JSON.stringify(error)}`)
    }
  }

  return (
    <PageLayout className="grid lg:grid-cols-2 place-items-start">
      <div className="p-4 sticky top-0 w-full h-full flex flex-col justify-center items-center">
        <img
          className="-z-10 absolute w-full h-full object-cover"
          src="/register-page.jpeg"
          alt="Nauvoo"
          draggable={false}
        />

        <div className="bg-background p-4 sm:p-8 max-w-lg w-full m-auto rounded-sm space-y-4">
          <div className="w-full flex items-center gap-4 sm:gap-8">
            <h2>Login</h2>

            <Svgs.title_leaf className="w-full h-full mt-1" />
          </div>

          <div className="w-full relative">
            {/* <p className="absolute text-xs p-4">
              Nauvoo Pageant Family Applications close on Oct 31 each season.
              Staff and other applications are open after this date by
              invitation.
            </p> */}

            <Svgs.square_box className="w-full h-full" />
          </div>

          <Button size="xl" variant="white" className="w-full" onClick={loginWithGoogle}>
            <Svgs.google />
            Login with Google
          </Button>

          <div className="w-full flex items-center gap-1">
            <hr className="w-full" />
            <h6 className="font-bold not-italic font-inter">OR</h6>
            <hr className="w-full" />
          </div>

          <Form {...loginForm}>
            <form onSubmit={loginForm.handleSubmit(handleLogin)} className="grid gap-4">
              <InputFormField
                Icon={Mail}
                label="Email Address"
                type="email"
                name="email"
                placeholder="johndoe@gmail.com"
                description="Your email is case sensitive."
                required
              />

              <InputFormField
                Icon={Lock}
                label="Create Password"
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="*****************"
                required
              />
              <CheckboxFormField name="" label="Show password" onClick={togglePasswordVisibility} />

              <CheckboxFormField label="Remember Me?" name="remember_me" />

              <Button size="lg">Login</Button>

              <div className="flex flex-col md:flex-row items-center justify-between gap-4">
                <p className="text-sm text-primary-500">
                  Don’t have an account?{" "}
                  <Link className="text-sm underline" to={`/register?email=${loginForm.watch("email") || ""}`}>
                    Register
                  </Link>
                </p>

                <Link className="text-primary-500 text-sm hover:underline" to="/forgot-password">
                  Forgot Password?
                </Link>
              </div>
            </form>
          </Form>
        </div>
      </div>

      <div className="h-full hidden lg:flex p-10 flex-col justify-between gap-20">
        <div className="flex flex-col gap-4">
          <div className="space-y-8">
            <Template slug="login/#blurb" />
          </div>
          {/*<img className="w-full h-full" src="/login-application-close.svg" alt="Application close" draggable={false} />*/}
        </div>

        <EnquireSection isSmallScreen />
      </div>
    </PageLayout>
  )
}

export default LoginPage
